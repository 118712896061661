import bem from "bem-ts"
import { getProducts } from "~/helpers/get-products"
import { getProductsIndex } from "~/helpers/get-products-index"
import { scrollTo } from '~/helpers/scroll'
import ButtonsHandler from '~/classes/tabs-block/buttons-handler'
import * as ScrollableContainer from "~/helpers/scrollable-container"
import { dataStore } from '~/helpers/data-store'
import throttle from "~/helpers/throttle"
import { setEventButtonLoaderIndex } from "./ajax-product-loader-index"
import { fetchRequest } from "~/helpers/fetch"
import { yandexDatalayerClick } from '~/helpers/yandex-datalayer'
import KeenSliderHelper from "~/classes/keen-slider-helper"
import { KeenSliderInstance } from "keen-slider"
import { getSvgSpriteIcon } from "~/helpers/get-svg-sprite-icon"
import { processingOfGoodsIncluded } from '~/client/vanilla-components/detail/sliders-goods-included'

const b = bem("tabs-card-block", { strict: false })
const productPageType = (document.querySelector('#product-page-type') as HTMLInputElement)?.value;

let cmsSliders: KeenSliderHelper[] = []

window.addEventListener('DOMContentLoaded', () => {
    const tabsBlocksContainers = <NodeListOf<HTMLElement>>document.querySelectorAll('.' + b())
    const parentIdContainer = <HTMLElement>document.querySelector(".product__code")
    const parentId: string = parentIdContainer ? <string>parentIdContainer.dataset.parentId : '0'

    if (!tabsBlocksContainers.length) return void 0
    tabsBlocksContainers.forEach((tabBlock: HTMLElement) => {
        const productId = tabBlock ? <string>tabBlock.dataset.productId : '0'
        const method = tabBlock ? <string>tabBlock.dataset.method : ''
        tabBlock.onclick = async (e) => {
            const newActiveTab = e.target as HTMLElement | null
            const countElementLoad = String(Number(newActiveTab?.dataset.count) < dataStore.tumberOfTabbedBlockBootstrapItems ? Number(newActiveTab?.dataset.count) : dataStore.tumberOfTabbedBlockBootstrapItems)
            if (!newActiveTab || !newActiveTab?.classList.contains(b('link'))) return void 0

            yandexDatalayerClick(newActiveTab)

            const idCategory = newActiveTab.dataset.category as string

            switch (true) {
                case newActiveTab.classList.contains(b('link') + '--catalog-group-switcher'):
                    handleCatalogGroupSwitching(newActiveTab)
                    setNewActiveTab(tabBlock, newActiveTab)
                    break
                case newActiveTab.classList.contains(b('link') + '--price-list-switcher'):
                    handlePriceListSwitching(e)
                    break
                case newActiveTab.classList.contains(b('link') + '--group-switcher'):
                    handleGroupSwitching(tabBlock, newActiveTab)
                    setNewActiveTab(tabBlock, newActiveTab)
                    break
                case newActiveTab.classList.contains(b('link') + '--group-switcher-with-subcategory'):
                    handleGroupSwitchingWithSubcategory(tabBlock, newActiveTab)
                    setNewActiveTab(tabBlock, newActiveTab)
                    break
                case newActiveTab.classList.contains(b('link') + '--group-switcher-subcategory'):
                    handleGroupSwitchingSubcategory(tabBlock, newActiveTab)
                    setNewActiveTab(tabBlock, newActiveTab, "." + b('link') + "--group-switcher-subcategory" + "." + b('link') + '--selected')
                    break
                default:
                    await loadProducts(tabBlock, method, productId, parentId, idCategory, '0', countElementLoad)
                    setNewActiveTab(tabBlock, newActiveTab)
                    buttonsProcessing(newActiveTab, tabBlock, idCategory)
            }
        }

        setEventButtonLoaderIndex(tabBlock)
    })

    const cmsList = document.querySelectorAll('.' + b('cms-list')) as NodeListOf<HTMLElement>

    if (cmsList) cmsList.forEach((element: HTMLElement, index: number) => handleCmsSlider(element, index))

    const moreLinks = document.querySelectorAll('.' + b('link-more')) as NodeListOf<HTMLElement>

    if (moreLinks.length) {
        moreLinks.forEach((moreLink: HTMLElement) => moreLink.addEventListener('click', (e: Event) => toggleLinks(e)))
    }

    document.querySelectorAll<HTMLElement>('.tabs-card-block__list').forEach(productList => {
        ScrollableContainer.registerListeners(productList)
        productList.addEventListener('click', (e: Event) => {
            const block = e.target as HTMLElement | null
            if (block) yandexDatalayerClick(block)

            if (productList.dataset.clickable === 'false') {
                e.preventDefault()
            }
        })
    })

    const throttledResize = throttle(() => {
        if (cmsList) cmsList.forEach((element: HTMLElement, index: number) => handleCmsSlider(element, index))
    }, 500)

    window.addEventListener('resize', () => {
        throttledResize()
    })

    window.addEventListener('scroll', () => {
        handlePriceListStickyHeader()
    })

    window.addEventListener('dropdownClose', () => {
        const priceListWrapper = document.querySelector('.tabs-card-block__header--price-list') as HTMLElement

        if (!priceListWrapper) return

        priceListWrapper.classList.add('no-check')
        const timeOut = setTimeout(() => {
            priceListWrapper.classList.remove('no-check')
            clearTimeout(timeOut)
        }, 500);
    })

    sameNewSetProcessing()
    processingOfGoodsIncluded()

    // проверка на серию
    if (productPageType === '1') {
        priseListAnchorIntersection()
    }
})

const sameNewSetProcessing = () => {
    const sameNewSetContainer = document.querySelector('.tabs-card-block__same-new-set')

    if (!sameNewSetContainer) {
        return void 0
    }

    sameNewSetContainer.addEventListener("click", (e) => {
        const target = e.target as HTMLDivElement

        if (!target.closest<HTMLElement>(".card__close")) {
            return void 0
        }

        const cardItem = target.closest<HTMLElement>(".tabs-card-block__item")

        if (!cardItem) {
            return void 0
        }

        const cardList = cardItem.parentNode as HTMLElement

        if (!cardList) {
            return void 0
        }

        cardList.removeChild(cardItem)


        const sameNewSetRemoveChildElementEvent = new Event("sameNewSetRemoveChildElementEvent", { bubbles: true }),
            sameNewSetListContainer = document.querySelector('.tabs-card-block__same-new-set .tabs-card-block__list')

        if (!sameNewSetListContainer) {
            return void 0
        }

        sameNewSetListContainer.dispatchEvent(sameNewSetRemoveChildElementEvent)
    })
}

const handleCmsSlider = (container: HTMLElement, index: number) => {
    if (!container) return void 0

    const children = container.childNodes as NodeListOf<HTMLElement>

    const xxsm = window.innerWidth > 360 && window.innerWidth < 640 && children.length < 2
    const xsm  = window.innerWidth > 640 && window.innerWidth < 960 && children.length < 3
    const sm   = window.innerWidth > 960 && children.length < 5;

    (xxsm || xsm || sm) ? destroyCmsSlider(container, index) : initCmsListSlider(container, index)
}

const destroyCmsSlider = (container: HTMLElement, index: number) => {
    if (container) {
        container.classList.remove('opacity-0')
        container.classList.remove('keen-slider')
        container.classList.add(b('list') + '--sliderless')
    }

    if (typeof cmsSliders[index] === 'undefined') return void 0

    cmsSliders[index].destroy()

    cmsSliders = cmsSliders.filter((item: KeenSliderHelper, indexSlider: number) => indexSlider !== index)

    container?.parentElement?.classList.remove(b('cms-list-wrapper') + '--start-fade')
    container?.parentElement?.classList.remove(b('cms-list-wrapper') + '--end-fade')
}

const initCmsListSlider = (container: HTMLElement, index: number) => {
    if (cmsSliders[index]) {
        return void 0
    }

    const slider = new KeenSliderHelper(
        container,
        {
            slides: {
                perView: 'auto',
                spacing: 4,
            },
        }
    )

    const arrowHtml = `<svg><use href="${getSvgSpriteIcon('icon-arrow-min')}"></use></svg>`

    slider.createAdditionalMarkup({
        arrows: {
            enable: true,
            html: arrowHtml,
        },
        dots: {
            enable: false
        },
        counter: {
            enable: false
        }
    })

    const children = container.querySelectorAll('.keen-slider__slide') as NodeListOf<HTMLElement>

    slider.instance.on('slideChanged', (e) => handleSlideChange(e, container, children.length))

    cmsSliders[index] = slider

    container.classList.remove(b('list') + '--sliderless')
    container.classList.remove('opacity-0')
}

const handleSlideChange = (sliderInstance: KeenSliderInstance, container: HTMLElement, count: number) => {
    const parent = container.parentElement as HTMLElement

    let progressEdning = 0.975

    if (window.innerWidth >= 360 && window.innerWidth < 640) progressEdning = 0.9861
    if (window.innerWidth >= 640 && window.innerWidth < 960) progressEdning = 0.9905

    if (sliderInstance.track.details.abs === 0) {
        parent.classList.remove(b('cms-list-wrapper') + '--start-fade')
        container.classList.add(b('cms-list') + '--end-fade')
        return void 0
    }

    if (sliderInstance.track.details.progress >= progressEdning
        || sliderInstance.track.details.abs == sliderInstance.track.details.maxIdx
    ) {
        parent.classList.add(b('cms-list-wrapper') + '--start-fade')
        container.classList.remove(b('cms-list') + '--end-fade')
        return void 0
    }

    parent.classList.remove(b('cms-list-wrapper') + '--start-fade')
    container.classList.add(b('cms-list') + '--end-fade')
}

let lastScrollTop = 0

// плавное раскрытие меню прайс листа и закрытие меню навигации при скролле
const handlePriceListStickyHeader = () => {
    const anchorsElement = document.querySelector('.' + b('list-title')) as HTMLElement
    const priceTabsMenu = document.querySelector('.tabs-card-block__header--price-list') as HTMLElement

    if (priceTabsMenu.classList.contains('no-check')) return

    const productNavigationList = document.querySelector('.product-navigation') as HTMLElement
    const headerContainer = document.querySelector('#header') as HTMLElement
    const scrollTop = window.scrollY || document.documentElement.scrollTop
    let directionTop: boolean = scrollTop < lastScrollTop ? true : false

    // если доскролил до прайс листа
    if (anchorsElement.offsetTop <= scrollTop) {
        if (directionTop) {
            // Скролл вверх
            priceTabsMenu.classList.remove(b('header--sticky-active_down'))
            productNavigationList ? productNavigationList.classList.add('border-none') : ''
            priceTabsMenu.classList.add(b('header--sticky-active_up'))
            headerContainer.classList.add('header__border-bottom')

            setTimeout(() => {
                lastScrollTop = scrollTop
            }, 1000)
        } else {
            // Скролл вниз
            priceTabsMenu.classList.remove(b('header--sticky-active_up'))
            productNavigationList ? productNavigationList.classList.remove('border-none') : ''
            headerContainer.classList.remove('header__border-bottom')
            priceTabsMenu.classList.add(b('header--sticky-active_down'))

            setTimeout(() => {
                lastScrollTop = scrollTop
            }, 1000)
        }
    }
}

const priseListAnchorIntersection = () => {
    const anchorBlocks = document.querySelectorAll('.tabs-card-block__list') as NodeListOf<HTMLElement>
    const anchorLinks = document.querySelectorAll('.tabs-card-block__link') as NodeListOf<HTMLElement>

    const thresholdOption = document.documentElement.clientWidth < 640 ? 0.5 : 1

    const options = {
        threshold: thresholdOption
    }

    const callback = (entries: IntersectionObserverEntry[]) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                anchorLinks.forEach(link => {
                    link.classList.remove('tabs-card-block__link--selected')
                })

                const parentElement = entry.target.parentElement
                const targetId = parentElement?.querySelector('.tabs-card-block__list-title')?.getAttribute('id')

                const activeLink = document.querySelector(`[href="#${targetId}"`)
                activeLink?.classList.add('tabs-card-block__link--selected')
            }
        })
    }

    const observer = new IntersectionObserver(callback, options)

    anchorBlocks.forEach(block => observer.observe(block))
}

const handlePriceListSwitching = (event: Event) => {
    event.preventDefault()
    const scrollTop = window.scrollY || document.documentElement.scrollTop
    const link = event.target as HTMLAnchorElement
    const anchorBlock = document.querySelector(`${link.getAttribute('href')}`) as HTMLElement
    const blockScrollPosition = anchorBlock.offsetTop

    if (scrollTop > blockScrollPosition) {
        scrollTo(link.hash, 0)
    } else {
        scrollTo(link.hash, -50)
    }
}

const loadProducts = async (tabBlock: HTMLElement, method: string, productId: string, parentId: string, idCategory: string, showed: string, countElementLoad: string) => {
    const catalogListContainer = tabBlock.querySelector('.' + b('list')) as HTMLElement
    catalogListContainer.style.opacity = '0'
    const response = await getProducts(method, productId, parentId, idCategory, '0', countElementLoad)

    if (!catalogListContainer) return void 0

    catalogListContainer.innerHTML = response.data.html
    catalogListContainer.style.opacity = '1'
}

const setNewActiveTab = (tabBlock: HTMLElement, newActiveTab: HTMLElement, selector: string = "." + b('link') + '--selected') => {
    const oldActiveTab = tabBlock.querySelector<HTMLElement>(selector)

    if (!oldActiveTab) return void 0

    oldActiveTab.classList.remove(b('link') + '--selected')
    newActiveTab.classList.add(b('link') + '--selected')
}

const buttonsProcessing = (newActiveTab: HTMLElement, tabBlock: HTMLElement, idCategory: string) => {
    const numberOfOutputElements = Number(tabBlock.querySelectorAll('.' + b('item') + "[data-category='" + idCategory + "']")?.length)
    const maxCountProducts = newActiveTab.dataset.count as string
    const remainingQuantityOfProducts: number = Number(maxCountProducts) - Number(numberOfOutputElements)
    const numberNextLoadElement = remainingQuantityOfProducts < dataStore.offsetProductsTabsBlock ? remainingQuantityOfProducts : dataStore.offsetProductsTabsBlock
    const buttonLoaderNextContainer = tabBlock.querySelector<HTMLElement>(".button__product-collection-loader--next")

    if (!buttonLoaderNextContainer) return void 0

    const buttonHelper = new ButtonsHandler(buttonLoaderNextContainer)

    buttonHelper.setNumberButtons(String(maxCountProducts), String(numberNextLoadElement))
    buttonHelper.showOrHideButton(Number(maxCountProducts), Number(numberNextLoadElement))
}

const handleGroupSwitching = (tabBlock: HTMLElement, newActiveLink: HTMLElement) => {
    const oldActiveLink = tabBlock.querySelector<HTMLElement>("." + b('link') + '--selected')
    const oldCategoryId = oldActiveLink?.dataset.category
    const newCategoryId = newActiveLink.dataset.category

    if (!oldCategoryId || !newCategoryId) return void 0

    const oldCategoryProductsGroup = tabBlock.querySelector('.' + b('groups-item') + `[data-category="${oldCategoryId}"]`)
    const newCategoryProductsGroup = tabBlock.querySelector('.' + b('groups-item') + `[data-category="${newCategoryId}"]`)

    if (!oldCategoryProductsGroup || !newCategoryProductsGroup) return void 0

    oldCategoryProductsGroup.classList.remove(b('groups-item') + '--selected')
    newCategoryProductsGroup.classList.add(b('groups-item') + '--selected')
}

const handleGroupSwitchingWithSubcategory = (tabBlock: HTMLElement, newActiveLink: HTMLElement) => {
    const oldActiveLink = tabBlock.querySelector<HTMLElement>("." + b('link') + '--selected.' + b('link') + '--group-switcher-with-subcategory'),
        oldCategoryId = oldActiveLink?.dataset.category,
        newCategoryId = newActiveLink.dataset.category

    if (!oldCategoryId || !newCategoryId) return void 0

    const oldCategoryProductsGroup = tabBlock.querySelector('.' + b('links-list-subcategory') + `[data-category="${oldCategoryId}"]`)
    const newCategoryProductsGroup = tabBlock.querySelector('.' + b('links-list-subcategory') + `[data-category="${newCategoryId}"]`)

    if (!oldCategoryProductsGroup || !newCategoryProductsGroup) return void 0

    newCategoryProductsGroup.classList.remove(b('links-list-subcategory') + '--hidden')
    oldCategoryProductsGroup.classList.add(b('links-list-subcategory') + '--hidden')

    const clickEvent = new Event('click', { "bubbles": true }),
        firstLinkInTab = newCategoryProductsGroup.querySelector('.' + b('link') + '--group-switcher-subcategory')

    if (!firstLinkInTab) return void 0

    firstLinkInTab.dispatchEvent(clickEvent)
}

const handleGroupSwitchingSubcategory = async (tabBlock: HTMLElement, newActiveLink: HTMLElement) => {
    const oldActiveLink = tabBlock.querySelector<HTMLElement>("." + b('link') + '--selected.' + b('link') + '--group-switcher-subcategory'),
        newCategoryIdSelector = newActiveLink.dataset.categorySelector

    if (!oldActiveLink) return void 0

    const oldCategoryProductsGroup = tabBlock.querySelector<HTMLElement>('.' + b('groups-item') + '--selected')
    const newCategoryProductsGroup = tabBlock.querySelector<HTMLElement>('.' + b('groups-item') + `[data-category-selector="${newCategoryIdSelector}"]`)

    if (!oldCategoryProductsGroup || !newCategoryProductsGroup) return void 0

    oldCategoryProductsGroup.classList.remove(b('groups-item') + '--selected')
    newCategoryProductsGroup.classList.add(b('groups-item') + '--selected')

    const productListContainer = newCategoryProductsGroup.querySelector<HTMLElement>("." + b('list')),
        productListOldContainer = oldCategoryProductsGroup.querySelector<HTMLElement>("." + b('list')),
        indexProductsContainer = tabBlock.closest<HTMLElement>(".index-products")

    if (!productListContainer || !indexProductsContainer || !productListOldContainer) return void 0

    const group = indexProductsContainer.dataset.group as string,
        categoryLink = indexProductsContainer.querySelector<HTMLElement>('.' + b('link') + '--selected')

    const currentPage = newActiveLink.dataset.page as string

    if (!categoryLink) return void 0

    resetOldContainer(oldActiveLink, productListOldContainer, oldCategoryProductsGroup)

    if (Number(currentPage) > 1) {
        return void 0
    }

    const newCategoryId = newActiveLink.dataset.category

    if (!newCategoryId) return void 0

    const tabsCardBlockCOntainer = newActiveLink.closest<HTMLElement>('.' + b('links-list-subcategory'))

    if (!tabsCardBlockCOntainer) return void 0

    const idTabCardBLock = tabsCardBlockCOntainer.dataset.category

    productListContainer.innerHTML = (await getProductsIndex(
        String(group),
        String(idTabCardBLock),
        String(newCategoryId),
        String(currentPage),
        Number(newActiveLink.dataset.itemsPerPage)
    )).data.html

    // Выкинул в отдельную макротаску, для проверки перфоманса #2
    setTimeout(() => ScrollableContainer.checkCardsVisibility(productListContainer))

    setNumberFirstLoadPage(newActiveLink)
}

const handleCatalogGroupSwitching = async (tabLink: HTMLElement) => {
    if (!tabLink.dataset.category) return void 0

    try {
        const response = await fetchRequest(`/module/leobootstrapmenu/ajax?category=${tabLink.dataset.category}`, {})

        if (!response.success || !response.data) return void 0

        const groupsElement = document.querySelector('.' + b('groups'))

        if (!groupsElement) return void 0

        groupsElement.innerHTML = response.data
    } catch (error) {
        console.error(error)
    }
}

const setNumberFirstLoadPage = (link: HTMLElement) => {
    link.dataset.page = "2"
}

const resetButton = (oldCategoryProductsGroup: HTMLElement) => {
    const button = oldCategoryProductsGroup.querySelector<HTMLElement>(".button__product-collection-loader--next-index")

    if (!button) return void 0

    button.classList.remove("!hidden")
}

const resetOldContainer = (oldActiveLink: HTMLElement, productListOldContainer: HTMLElement, oldCategoryProductsGroup: HTMLElement) => {
    const itemsPerPage = parseInt(oldActiveLink.dataset.itemsPerPage ?? '4')
    const fullProductContainerList = productListOldContainer.querySelectorAll(".tabs-card-block__item:nth-child(-n+" + itemsPerPage + ")"),
        productContainerList = productListOldContainer.querySelectorAll(".tabs-card-block__item")

    if (productContainerList.length <= itemsPerPage) return void 0

    productListOldContainer.innerHTML = ""

    fullProductContainerList.forEach(product => {
        productListOldContainer.appendChild(product)
    })

    resetButton(oldCategoryProductsGroup)
    setNumberFirstLoadPage(oldActiveLink)
}

const toggleLinks = (event: Event) => {
    const moreLink = event.target as HTMLElement

    if (moreLink.classList.contains(b('link-more--less'))) {
        moreLink.classList.remove(b('link-more--less'))
        moreLink.innerHTML = "ещё"

        const showLinks = moreLink.parentElement?.querySelectorAll('.' + b('link--show')) as NodeListOf<HTMLElement>
        showLinks.forEach((showLink: HTMLElement) => {
            showLink.classList.remove(b('link--show'))
        })

        return void 0
    }

    moreLink.classList.add(b('link-more--less'))
    moreLink.innerHTML = "скрыть"

    const showLinks = moreLink.parentElement?.querySelectorAll('.' + b('link')) as NodeListOf<HTMLElement>
    showLinks.forEach((showLink: HTMLElement) => {
        showLink.classList.add(b('link--show'))
    })
}
