import bem from "bem-ts"
import { HtmlHTMLAttributes } from "vue"
import { deleteCookie, getCookie } from '~/helpers/cookies'
import { fetchRequest } from "~/helpers/fetch"
import KeenSliderHelper from '~/classes/keen-slider-helper'
import { googleDataMarketingBanner, googleDataMarketingBannerClick  } from '~/helpers/google-datalayer'

interface categoryArray {
    id_category_group:  number
    html:   HTMLElement
}

window.addEventListener('DOMContentLoaded', () => {
    const b = bem("main-menu", { strict: false })

    interface MainMenuElements {
        dropdownContent:    null | HTMLElement
        leftSide:           null | HTMLElement
        rightSide:          null | HTMLElement
        backButton:         null | HTMLElement
        openedFirstLevel:   null | HTMLElement
        openedSecondLevel:  null | HTMLElement
        openedThirdLevel:   null | HTMLElement
    }

    const categoryArray:categoryArray[] = []

    const elements: MainMenuElements = {
        dropdownContent: null,
        leftSide: null,
        rightSide: null,
        backButton: null,
        openedFirstLevel: null,
        openedSecondLevel: null,
        openedThirdLevel: null
    }

    const wrap = document.querySelector<HTMLElement>('.categories-list--preview')

    if (wrap) {
        const categoriesList = wrap.querySelectorAll<HTMLElement>('.category')
        categoriesList?.forEach((catL: HTMLElement) => {
            const item : categoryArray = {
                'id_category_group' : Number(catL.dataset.group),
                'html': catL
            }
            categoryArray.push(item)
        })
    }

    const setMainMenuElements = () => {
        elements.dropdownContent = document.querySelector<HTMLElement>('.dropdown-screen[data-relation="main-menu"] .dropdown-screen__content')
        elements.leftSide = document.querySelector<HTMLElement>('.' + b('groups-list-wrapper'))
        elements.rightSide = document.querySelector<HTMLElement>('.' + b('sublevels-list'))
        elements.backButton = document.querySelector<HTMLElement>('.' + b('back'))
        elements.openedFirstLevel = document.querySelector<HTMLElement>('.' + b('groups-item') + '--opened')
        elements.openedSecondLevel = document.querySelector<HTMLElement>('.' + b('list-item') + '--opened')
        elements.openedThirdLevel = document.querySelector<HTMLElement>('.' + b('sublevels-item') + '--opened')
    }

    const handleInitialLoad = () => {
        setMainMenuElements()
        // Выкинул в отдельную макротаску, для проверки перфоманса #1
        setTimeout(() => setMenuContentHeight())
    }

    const setMenuContentHeight = () => {
        if (!elements.dropdownContent || !elements.rightSide || !elements.openedThirdLevel) return void 0

        if (window.innerWidth < 640 && getCookie('selected-menu-group-item')) {
            elements.dropdownContent.style.height = elements.openedThirdLevel.offsetHeight + 'px'
            elements.rightSide.style.height = elements.openedThirdLevel.offsetHeight + 'px'

            elements.backButton?.classList.toggle(b('back') + '--opened')
            elements.rightSide?.classList.add(b('sublevels-list') + '--opened')
            elements.leftSide?.classList.add(b('groups-list-wrapper') + '--hidden')
        } else {
            elements.dropdownContent.style.height = 'auto'

            if (window.innerWidth >= 640) {
                elements.rightSide.style.height = elements.openedThirdLevel.offsetHeight + 'px'
            }
        }
    }

    const handleMenuClickEvents = () => {

        document.addEventListener('click', (e: Event) => {
            const eventTarget = e.target as HTMLElement

            switch (true) {
            case eventTarget.classList.contains(b('groups-item-link')):
                handleFirstLevel(eventTarget)
                break
            case eventTarget.classList.contains(b('list-item')):
                e.stopPropagation()
                handleSecondLevel(eventTarget)
                break
            case eventTarget.classList.contains('tabs__link'):
                if(eventTarget.classList.contains('tabs__link--category-groups')) {
                    if (eventTarget.dataset.tab != undefined) {

                        const eventTarget = e.target as HTMLElement

                        const wrapItems = document.querySelector<HTMLElement>('.categories-list--preview')
                        if (!wrapItems) return void 0
                        const items = wrapItems.querySelectorAll<HTMLElement>('.category')

                        items.forEach((tab: HTMLElement) => {
                            if (!tab.classList.contains('categories-all')) {
                                tab.remove()
                            }
                        })

                        categoryArray.forEach((tab: categoryArray) => {
                            if (Number(eventTarget.dataset.tab) == tab.id_category_group || eventTarget.dataset.tab == 'all') {
                                wrapItems?.appendChild(tab.html)
                            }
                        })

                        const wrapItemsAll = document.querySelector<HTMLElement>('.categories-list--all')
                        if (!wrapItemsAll) return void 0
                        const itemsAll = wrapItemsAll.querySelectorAll<HTMLElement>('.category')
                        if (!itemsAll) return void 0
                        itemsAll.forEach((tab: HTMLElement) => {
                            if (tab.dataset.group == eventTarget.dataset.tab || eventTarget.dataset.tab == 'all') {
                                tab.classList.remove("hidetab")
                            } else {
                                tab.classList.add("hidetab")
                            }
                        })
                    }
                } else {
                    eventTarget.addEventListener('toggleMenuTag', setMenuContentHeight)
                }
                break
            case eventTarget.classList.contains('main-menu__tabs-item'):
                handleTabs(eventTarget)
                break
            }
        })
    }

    const dispatcherClick = (el: HTMLElement) => {
        const eventClick = new Event("click", {
            bubbles: true
        })

        el.dispatchEvent(eventClick)
    }

    const toogleClassTabs = (el: HTMLElement) => {
        const className = 'main-menu__tabs-item--opened',
            activeTabContainer = document.querySelector('.' + className)

        if (!activeTabContainer) return void 0

        activeTabContainer.classList.remove(className)
        el.classList.add(className)
    }

    const handleTabs = (el: HTMLElement) => {
        const id = el.dataset.id,
            groupsItemContainer = document.querySelector<HTMLElement>(`.main-menu__groups-item[data-id="${id}"] .main-menu__groups-item-link`)

        if (!groupsItemContainer) return void 0

        dispatcherClick(groupsItemContainer)
        toogleClassTabs(el)
    }

    const handleFirstLevel = (el: HTMLElement) => {
        const parent = el.closest<HTMLElement>('.main-menu__groups-item:not(.main-menu__groups-item--empty):not(.main-menu__groups-item--opened')

        if (!parent) return void 0

        elements.openedFirstLevel?.classList.remove(b('groups-item') + '--opened')
        parent.classList.add(b('groups-item') + '--opened')

        elements.openedFirstLevel = parent

        const menuGroupId = parent.dataset.id

        if (menuGroupId) {
            document.cookie = `selected-menu-group=${menuGroupId}; path=/; max-age=2628000`

            if (window.innerWidth >= 640) {
                const firstGroupSublevel = parent.querySelector<HTMLElement>('.' + b('list-item'))

                if (firstGroupSublevel) {
                    firstGroupSublevel.click()
                }
            } else if (elements.openedThirdLevel) {
                elements.openedThirdLevel.classList.remove(b('sublevels-item') + '--opened')
                deleteCookie('selected-menu-group-item')
            }
        } else {
            deleteCookie('selected-menu-group')
            deleteCookie('selected-menu-group-item')
        }
    }

    const handleSecondLevel = async (el: HTMLElement) => {
        if (!elements.rightSide || !elements.leftSide || !elements.dropdownContent) return void 0

        elements.leftSide.classList.add(b('groups-list-wrapper') + '--hidden')
        elements.rightSide.classList.add(b('sublevels-list') + '--opened')

        elements.openedSecondLevel?.classList.remove(b('list-item') + '--opened')
        el.classList.add(b('list-item') + '--opened')
        elements.openedSecondLevel = el

        const isLoaded = el.dataset.isLoaded
        const menuItemId = el.dataset.id
        const menuGroupId = el.dataset.groupId

        if (!menuItemId) {
            deleteCookie('selected-menu-group-item')
        } else {
            document.cookie = `selected-menu-group-item=${menuItemId}; path=/; max-age=2628000`
        }

        const newThirdLevel = document.querySelector<HTMLElement>(`.${b('sublevels-item')}[id='${el.id}-full']`)

        if (menuGroupId && menuItemId && (!isLoaded || isLoaded !== 'true')) {
            const html =  await fetchThirdLevel(menuGroupId, menuItemId)
            const newThirdLevelContent = newThirdLevel?.querySelector('.main-menu-sublevel__list ')

            if (newThirdLevelContent && html != undefined) {
                newThirdLevelContent.innerHTML = html

                if (html != "") {
                    el.dataset.isLoaded = 'true'
                }
            }
        }

        if (newThirdLevel) {
            elements.openedThirdLevel?.classList.remove(b('sublevels-item') + '--opened')
            newThirdLevel.classList.add(b('sublevels-item') + '--opened')
            elements.openedThirdLevel = newThirdLevel
            setMenuContentHeight()
        }
    }

    const handleBackButton = () => {
        if (!elements.backButton) return void 0

        elements.backButton.addEventListener('click', () => {
            if (!elements.backButton || !elements.rightSide || !elements.leftSide || !elements.dropdownContent) return void 0
            const activeListItem = document.querySelector<HTMLElement>('.' + b('list-item') + '--opened')
            const activeSublevelItem = document.querySelector<HTMLElement>('.' + b('sublevels-item') + '--opened')

            if (!activeListItem || !activeSublevelItem) return void 0

            elements.backButton.classList.remove(b('back') + '--opened')
            activeListItem.classList.remove(b('list-item') + '--opened')
            activeSublevelItem.classList.remove(b('sublevels-item') + '--opened')
            elements.rightSide.classList.remove(b('sublevels-list') + '--opened')
            elements.leftSide.classList.remove(b('groups-list-wrapper') + '--hidden')
            elements.dropdownContent.style.height = 'auto'
            elements.rightSide.style.height = 'auto'
        })
    }

    const fetchThirdLevel = async (groupId: string, menuItemId: string, attemptNumber = 1): Promise<string | undefined> => {
        try {
            const response = await fetchRequest(`/main-menu/group/${groupId}/btmegamenu/${menuItemId}/3rd-level/`, {})

            if (response === undefined && attemptNumber > 4) {
                return ""
            }

            if (response === undefined) {
                const delayedRequest = async () => {
                    return new Promise((resolve) => {
                        setTimeout(() => {
                            resolve('')
                        }, 150)
                    })
                }

                await delayedRequest()

                return fetchThirdLevel(groupId, menuItemId, ++attemptNumber)
            }

            return response.data.html
        } catch (error) {
            console.error(error)
        }
    }

    const initBanners = () => {
        setTimeout(() => {
            const banners = document.querySelectorAll<HTMLElement>('.menu-banner')
            if (banners.length === 0) return void 0

            banners.forEach(banner => {
                const slider = new KeenSliderHelper(
                    banner,
                    {
                        loop: false,
                        plugins: []
                    },
                )

                slider.createAdditionalMarkup({
                    arrows: {
                        enable: false,
                    },
                    dots: {
                        enable: false
                    },
                    counter: {
                        enable: false
                    },
                    popup: {
                        enable: true,
                        selector: '.slider-banners__adv-button'
                    }
                })

                banner.classList.remove('opacity-0')
            })
        })
    }

    const initAdvBanners = () => {     
        setTimeout(() => {
            const banners = document.querySelectorAll<HTMLElement>('.adv-menu-banner')
            if (banners.length === 0) return void 0

            banners.forEach(banner => {
                const menuBlock = banner.closest('.main-menu-sublevel')
                // const advBanner = menuBlock?.querySelector<HTMLElement>(`.main-menu-sublevel__adv-banner-container .slider-banners`)
                // const bannerSimple = menuBlock?.querySelector<HTMLElement>(`.main-menu-sublevel__banner-container .keen-slider-wrapper`)
                // const menuList = menuBlock?.querySelector<HTMLElement>(`.main-menu-sublevel__list`)

                menuBlock?.classList.add('main-menu-sublevel--with-banner')

                // if (menuList && advBanner && bannerSimple) {
                //     (menuList.offsetHeight < advBanner.offsetHeight) ? 
                //         menuBlock?.classList.add(`main-menu-sublevel--adv-priority`) :
                //         menuBlock?.classList.remove(`main-menu-sublevel--adv-priority`)
                // }
                
                const slider = new KeenSliderHelper(
                    banner,
                    {
                        loop: false,
                        plugins: []
                    },
                )

                slider.createAdditionalMarkup({
                    arrows: {
                        enable: false,
                    },
                    dots: {
                        enable: false
                    },
                    counter: {
                        enable: false
                    },
                    popup: {
                        enable: true,
                        selector: '.slider-banners__adv-button'
                    }
                })

                banner.classList.remove('opacity-0')
                googleDataMarketingBanner(banner)
                initClickListener(banner)
                
            })

        })
    }

    const initClickListener = (target: HTMLElement) => {
        target.addEventListener('click', handleClick)
    }
    
    const handleClick = (e: Event) => {
        const eventTarget = e.target as HTMLElement
        const currentSlide = eventTarget.closest<HTMLElement>('.keen-slider__slide--current')
    
        if (currentSlide) {
            googleDataMarketingBannerClick(currentSlide)
        }
    }

    handleInitialLoad()
    handleMenuClickEvents()
    handleBackButton()
    initBanners()
    initAdvBanners()
})
